import { vehicle } from './../../dataModel/vehicle.model';
import { supervisor } from './../../dataModel/supervisor.model';
import { driver } from './../../dataModel/driver.model';
import { planList } from './../../dataModel/plan-list-model';
import { Plantype } from './../../dataModel/Plan-type.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

 //shared config
import { Configuration } from '../../../shared/configurations/shared.config';

//Models
import { RelativeRelations } from '../../dataModel/relative-relations.model';
import { Students } from "../../dataModel/students.model";
import { Grade } from '../../dataModel/grade.model';
import { ClassModel } from '../../dataModel/class.model';
import { Branch } from '../../dataModel/branch.model';
import { SubBranch } from '../../dataModel/sub-Branch.model';
import { IdentityType } from '../../dataModel/identity-type.model';
import { Gender } from '../../dataModel/gender.model';
import { GuardianShip } from '../../dataModel/Guardian.model';
import { Landmark } from "../../dataModel/landmark.model";
import { Bus } from '../../dataModel/buss.model';
import { PlannedTripStudentModel } from '../../dataModel/PlannedTripStudentModel';
// import {  Landmark } from '../../dataModel/landmark.model';
@Injectable({
  providedIn: 'root'
})
export class LookUpsService {


  constructor(private http: HttpClient) { }

  getRelativeRelations(): Observable<RelativeRelations[]> {
    return this.http.get<RelativeRelations[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetRelativeRelationURL);
  }
  getGuardians(SubId: string): Observable<GuardianShip[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);

    return this.http.get<GuardianShip[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetGuardiansURL, { params: Params });
  }
  getplans(SubId: string): Observable<planList[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);
    return this.http.get<planList[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetPlans, { params: Params });
  }
  getplantypes(): Observable<Plantype[]> {
    return this.http.get<Plantype[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetPlantypeURL);
  }
  getStudents(SubId: string): Observable<Students[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);

    return this.http.get<Students[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetStudentsURL, { params: Params });
  }
  getStudentsByGradeId( GradeId : number , SubId: string , PlanTypeId : number): Observable<PlannedTripStudentModel[]> {
    debugger
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('GradeId', GradeId.toString());
    Params = Params.append('SubId', SubId);
    Params = Params.append('PlanTypeId', PlanTypeId.toString());
    

    return this.http.get<PlannedTripStudentModel[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetStudentsByGradeIdURL, { params: Params });
  }

  getIdentityTypes(): Observable<Grade[]> {
    return this.http.get<IdentityType[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetIdentityTypesURL);
  }

  getGrades(SubId: string): Observable<Grade[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);
    return this.http.get<Grade[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetGradeURL, { params: Params });
  }

  getClassByID(gradeId: number): Observable<ClassModel[]> {
    return this.http.get<ClassModel[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetClassURL + "?gradeId=" + gradeId);
  }

  getBranchs(staffId: string): Observable<Branch[]> {
    return this.http.get<Branch[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetBranchURL + "?staffId=" + staffId);
  }
  getLandmark(branchId: string, subBranchId: string, SubId: string): Observable<Landmark[]> {

    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('branchId', branchId);
    Params = Params.append('subBranchId', subBranchId);
    Params = Params.append('SubId', SubId);

    return this.http.get<Landmark[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetLandmarkURL, { params: Params });
  }
  getLandmarkWithLocation(branchId: string, subBranchId: string, SubId: string): Observable<Landmark[]> {
    let Params = new HttpParams();
    Params = Params.append('branchId', branchId);
    Params = Params.append('subBranchId', subBranchId);
    Params = Params.append('SubId', SubId);
    return this.http.get<Landmark[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetLandmarkWithLocationURL, { params: Params });
  }
  getSubBranchsByID(branchId: string, staffId: string): Observable<SubBranch[]> {
    return this.http.get<SubBranch[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetSubBranchURL + "?branchId=" + branchId + "&staffId=" + staffId);
  }

  getGender(): Observable<Gender[]> {
    return this.http.get<Gender[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetGenderNewURL);
  }
  // getDrivers() : Observable<driver[]>
  // {
  //   return this.http.get<driver[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetDrivers);
  // }
  Getstaff(SubId: string): Observable<supervisor[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);

    return this.http.get<supervisor[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apigetsupervisors, { params: Params });
  }
  getvehicles(SubId: string): Observable<vehicle[]> {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('SubId', SubId);

    return this.http.get<vehicle[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apigetvehicles, { params: Params });
  }
  getBuses(branchId: string, subBranchId: string): Observable<Bus[]> {

    return this.http.get<Bus[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetbusesURL + "?branchId=" + branchId + "&subId=" + subBranchId)
  }

  getBusBySubID(subId : string) : Observable<Bus[]>{
    return this.http.get<Bus[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetBusBySubIDURL + "?subId=" + subId)

  }
  getStaff(branchId: string, subBranchId: string): Observable<supervisor[]> {
    return this.http.get<supervisor[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetStaffURL + "?branchId=" + branchId + "&subId=" + subBranchId)
  }
  getStaffs( subBranchId: string): Observable<supervisor[]> {
    return this.http.get<supervisor[]>(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetStaffURL + "?SubId=" + subBranchId)
  }
  gettripstatus():Observable<any>
  {
    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.lookupsUrl.apiGetTripstatus);
  }
}
